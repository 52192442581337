/**
 * Created by Soufien on 30/06/16.
 */


function fix_admin_header() {
    var equalizeHeight = function () {
        var sidebarH = $("#sidebar-wrapper").height();
        console.log(sidebarH);
        $('main.main').css('min-height', sidebarH + 50);

    };

    var adaptHeaderAdmin = function () {
        var sidebarH = $("#wpadminbar").height();
        $('.navbar.navbar-default.navbar-fixed-top').css('margin-top', sidebarH);

    };

    equalizeHeight();
    adaptHeaderAdmin();

    $('.collapse').on('shown.bs.collapse', function () {
        equalizeHeight();
    }).on('show.bs.collapse', function () {
        equalizeHeight();
    });

    $('.collapse').on('hidden.bs.collapse', function () {
        equalizeHeight();
    }).on('hide.bs.collapse', function () {
        equalizeHeight();
    });

}

function renitialiseCards() {
    var cardTitle = $(".card-title.block-with-text");
    var cardText = $(".card-text.block-with-text");

    for (index = 0; index < cardTitle.length; ++index) {
        $clamp(cardTitle[index], {clamp: 3});
        $clamp(cardText[index], {clamp: 4});

    }

    cardTitle.css("min-height", "53px");
    $(".block-title").css("min-height", "111px");

    cardText.css("min-height", "76px");
}

function custom_initializer() {

// This is a fix for making sidebar height dont exceed main content conatiner

    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    var windows = ua.indexOf("Windows");
    var Firefox = ua.indexOf("Firefox");
    var Edge = ua.indexOf("Edge");


    if (msie > 0 || Firefox > 0 || Edge > 0 || windows > 0) {
        renitialiseCards();

        $('#event-cards-carousel').on('slid.bs.carousel', function () {
            console.log("sliding ended");
            renitialiseCards();
        });


    }


}

function show_modal(error) {

    if (error == "error") {
        $modal = $('.modal-frame.error');
        console.log(".modal-frame.error");

    } else {
        $modal = $('.modal-frame.success');
        console.log(".modal-frame");

    }
    $overlay = $('.modal-overlay');

    $("body").addClass("stop-scrolling");
    $overlay.addClass('state-show');
    $modal.removeClass('state-leave').addClass('state-appear');

}


function close_modal() {

    $modal = $('.modal-frame');
    $overlay = $('.modal-overlay');


    $(".stop-scrolling").removeClass('stop-scrolling');
    $overlay.removeClass('state-show');
    $modal.removeClass('state-appear').addClass('state-leave');

}

function prepare_modal() {
    $(document).on('ready', function () {
        $modal = $('.modal-frame        ');

        /* Need this to clear out the keyframe classes so they dont clash with each other between ener/leave. Cheers. */
        $modal.bind('webkitAnimationEnd oanimationend msAnimationEnd animationend', function (e) {
            if ($modal.hasClass('state-leave')) {
                $modal.removeClass('state-leave');
            }
        });

        $('.close-modal').on('click', function () {
            close_modal();
        });

    });
}

function participant_form_submit_handler() {

    prepare_modal();

    $('#participer_recherche').on('submit', function (e) {
        e.preventDefault();
        var $this = $(this);

        var pseudo = $('#pseudo').val();
        var mail = $('#mail').val();

        if (!$('#monsieur').is(':checked') && !$('#madame').is(':checked')) {

        }

        if (pseudo === '' || mail === '') {
            alert('Les champs doivent êtres remplis');
        } else {
            // Envoi de la requête HTTP en mode asynchrone
            $.ajax({
                url: window.location.href,
                type: $this.attr('method'),
                data: $this.serialize(),
                success: function (html) {
                    //console.log(html);
                    if (html.match(/<e>/)) {
                        console.log("error");
                        show_modal("error");
                    } else {
                        console.log("success");
                        show_modal();
                    }
                }
            });
        }
    });
}

function membre_form_submit_handler() {

    $("select")
        .change(function () {
            $("select option:selected").each(function () {
                /*
                 Autre professionnel de la santé : pro-sante
                 Décideur ou gestionnaire : desideur-gest
                 Chercheur : chercheur
                 Professionnel de recherche : pro-recherch
                 Étudiant : etudiant
                 Autre : autre

                 * */


                switch ($(this).val()) {
                    case "3":
                        input_class = "interest-options-tobe-hidden";
                        break;
                    case "11":
                        input_class = "pro-sante";
                        break;
                    case "12":
                        input_class = "desideur-gest";
                        break;
                    case "13":
                        input_class = "chercheur";
                        break;
                    case "14":
                        input_class = "pro-recherche";
                        break;
                    case "15":
                        input_class = "etudiant";
                        break;
                    case "16":
                        input_class = "autre";
                        break;
                    default:
                        input_class = "";
                        break;
                }

                if (["3","4","5","6","7","8","9","10"].indexOf($(this).val())>-1){
                    input_class = "interest-options-tobe-hidden";
                    $('.' + input_class).css("display", "block");
                    console.log($(this).val());
                    console.log("interest-options-tobe-hidden");
                }else{

                    $('.autres').css("display", "none");

                    $('.' + 'interest-options-tobe-hidden').css("display", "none");

                    if (input_class != "") {
                        $('.' + input_class).css("display", "block");
                    }
                }
            });

        });

    prepare_modal();

    $('#membre-form').on('submit', function (e) {
        e.preventDefault();
        var $this = $(this);

        var pseudo = $('#pseudo').val();
        var mail = $('#mail').val();


        if (!$('#monsieur').is(':checked') && !$('#madame').is(':checked')) {

        }

        if (pseudo === '' || mail === '') {
            alert('Les champs doivent êtres remplis');
        } else {
            // Envoi de la requête HTTP en mode asynchrone
            $.ajax({
                url: window.location.href,
                type: $this.attr('method'),
                data: $this.serialize(),
                success: function (html) {
                    //console.log(html);
                    if (html.match(/<e>/)) {
                        console.log("error");
                        show_modal("error");
                    } else {
                        console.log("success");
                        show_modal();
                    }
                }
            });
        }
    });

}


function scrollAnchor() {
    $('article .anchor').on('click', function (event) {
        //
        event.preventDefault();

        if ($('.navbar-fixed-top').is(":visible")) {
            $('html, body').animate({
                scrollTop: $($(this).attr('href')).offset().top - 164
            }, 2000);

        } else {

            $('html, body').animate({
                scrollTop: $($(this).attr('href')).offset().top - 10
            }, 2000);

        }

    });
}


function resetHoverOnTopMenu() {

    $(".sub-menu").hide();
    $(".header-menu").css("border-bottom", "solid 1px #edeede");
    // This used to reset hover event handler on top menu

}
function initHeaderAndMenuEventsHandlers() {

    // Add hover event handler for top menu to show corresponding submenu
    $(".main-nav a").hover(
        function (e) {
            // Reset
            $(".sub-menu").hide();

            // Show only specific one
            var element = $(e.currentTarget).data("hover");
            $("#" + element).show();
            $(".header-menu").css("border-bottom", "none");


        }
    );


    // Call to handle reset hover on the top menu
    $("body,#page-content-wrapper, .page-header, footer, header, .top-menu").hover(
        function () {
            //Menu is not hovered
            resetHoverOnTopMenu();
        }
    );


    // Add a click handler for search btn on top header
    $(".search-btn").click(
        function (e) {
            // Reset
            if ($(".main-menu").is(":visible")) {
                $(".main-menu").css("display", "none");
                $(".search-form").css("display", "flex");
            } else {
                $(".main-menu").css("display", "block");
                $(".search-form").css("display", "none");
            }
        }
    );


    // Toggle side bar menu
    $("#menu-toggle").click(function (e) {
        e.preventDefault();
        $("#wrapper").toggleClass("toggled");
    });


    $('#search-btn').on('click', function (e) {

        if ($('#search-btn').hasClass('icon-Icon_search')) {
            $('#search-btn').removeClass('icon-Icon_search');
            $('#search-btn').addClass('icon-Icon_X_menu');

            // Show search form
            $('#search-bar-mobile').css("display", "block");

        } else {
            $('#search-btn').removeClass('icon-Icon_X_menu');
            $('#search-btn').addClass('icon-Icon_search');

            // Hide search form
            $('#search-bar-mobile').css("display", "none");

        }
    });

    $("#main-menu-toggle,.icon-Icon_X_menu").click(function (e) {
        e.preventDefault();
        $('#carousel-cover').carousel('pause');
        $("body").toggleClass("toggled");
    });


    // Action when clicking the close button
    $(".btn-close-menu").click(function (e) {
        e.preventDefault();
        resetHoverOnTopMenu();
    });

}


function initSplitEvent() {

    $("#go-invert").click(function (e) {
        e.preventDefault();


        $(".homme-femme-text").text();

        if ($(".homme-femme-text").text() == "Afficher le risque des hommes") {
            $(".homme-femme-text").text("Afficher le risque des femmes")
        } else {
            $(".homme-femme-text").text("Afficher le risque des hommes")
        }

        // I can get this work with toggleClass :p
        if ($("#main-container").hasClass("right")) {
            $("#main-container").removeClass("right").addClass("left");
        } else if ($("#main-container").hasClass("left")) {
            $("#main-container").removeClass("left").addClass("right");
        }
    });


}

function quiz_check_listener() {
    $('input:radio').on('change', function (e) {
        //access value of changed radio group with $(this).val()
        if ($(":radio:checked").size() == 5) {

            $('.reponse-quiz').css("display", "none");
            // Default : Show no response
            var default_response = "reponse-non";

            $(':radio:checked').each(function () {
                console.log($(this).val());
                if ($(this).val() == 0) {
                    // show yes response
                    default_response = "reponse-oui";
                }
            });

            $('#' + default_response).css("display", "block");
        }

    });
}